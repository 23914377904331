@import "antd/dist/reset.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap");

@keyframes layout-title-hide {
    0% {
        display: none;
        opacity: 0;
    }
    80% {
        display: none;
        opacity: 0;
    }
    to {
        display: unset;
        opacity: 1;
    }
}

body {
    font-family: "Inter", sans-serif;
}

.site-layout-background {
    min-height: 100vh;
}

.slider-logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.slider-logo h1 {
    display: inline-block;
    height: 32px;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    vertical-align: middle;
    animation: layout-title-hide 0.2s;
}

.ant-layout-sider-collapsed .slider-logo h1 {
    display: none;
}

.ant-picker-month-panel .ant-picker-cell-inner {
    width: 75px;
}

.header {
    background: #fff;
    padding: 0;
    height: 92px;
    width: 100%;
}

.upload-file {
    display: none;
}

.ant-table-thead .ant-table-cell {
    background-color: #5c88f8;
    color: #fff;
    border: 0;
    font-weight: 500;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px;
    border-radius: 8px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 44px;
}

.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    height: 44px;
}

.ant-layout-sider-children {
    background-color: #FBFCF5;
}

.ant-menu-item-selected {
    color: #1890ff;
    font-weight: 500;
    background-color: white;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: white;
    border-radius: 8px;
    height: 44px;
}

.ant-tag {
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.ant-modal-header {
    background-color: #5c88f8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ant-modal-title {
    color: white;
}

.ant-modal-content {
    border-radius: 8px;
}

.ant-modal-close {
    color: "white";
}

.ant-input-number-input {
    height: 40px;
}

.ant-input-number {
    border-radius: 8px;
    height: 44px;
}

.ant-input-number-group-addon {
    border-radius: 8px;
}

.ant-picker {
    border-radius: 8px;
    height: 44px;
}

.ant-input {
    border-radius: 8px;
    height: 44px;
}

.ant-input-password {
    border-radius: 8px;
    /* height: 44px; */
    padding: 0px 11px;
}

.ant-layout-header {
    line-height: 30px;
}

.ant-drawer-body {
    background: white !important;
}

.ant-card {
    border-radius: 6px;
}

.ant-modal-close-x {
    color: #fff;
}

.table-row-light {
    background-color: #ffffff;
}

.table-row-dark {
    background-color: #e4d7c5;
}
